// @flow

import React from 'react';

import { GatsbyImage } from "gatsby-plugin-image";

import ReactHtmlParser from 'react-html-parser';

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';

import HtmlHead from '../components/HtmlHead';
import transformHtml from '../components/transformHtml';

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    graphCmsBlogPost(slug: { eq: $slug }) {
      title
      slug
      createdAt
      updatedAt
      excerpt
      body {
        html
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(maxWidth: 600, layout: FLUID)
          }
        }
      }
    }
  }
`;

export default function BlogPost(props: Object) {
  const {location, data} = props;
  const post = data.graphCmsBlogPost;
  const {title, image, slug, body, excerpt, createdAt, updatedAt} = post;

  const imgData = image.localFile.childImageSharp.gatsbyImageData;
  const imgUrl = imgData.images.fallback.src;

  const pathname = location && location.pathname;
  const pageUrl = process.env.GATSBY_CLIENT_URL + pathname;

  return (
    <Div p={{t: '2rem'}} className='long-text'>
      <HtmlHead title={`${title} | bukitesveiki.lt`} description={excerpt}
        extraStructData={{
          "@type": "BlogPosting",
          "headline": title,
          "author": {
            "@type": "Organization"
          },
          "publisher": {
            "@type": "Organization"
          },
          "datePublished": createdAt,
          "dateModified": updatedAt,
          "image": [
            imgUrl
          ],
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageUrl
          }
        }}
        />
      <Container>
        <Row>

          <Col size={{xs: 12, lg: 8}}>
            <Text tag='h2' textSize='display2' textWeight='600' p={{b: '2rem'}}>
              {title}
            </Text>
            {
              ReactHtmlParser(body.html, {transform: transformHtml})
            }
          </Col>
          <Col size={{xs: 12, lg: 4}}>
            <GatsbyImage image={imgData} alt={`${title} paveikslėlis`} />
          </Col>
        </Row>
      </Container>
    </Div>
  );
}
